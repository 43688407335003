<script setup lang="ts">
import { watch } from "vue";
import { SvgIcon } from "../";

const emit = defineEmits<{
	(e: "update:modelValue", value: number): void;
}>();

interface Props {
	name: string;
	modelValue: number;
	min?: number;
	max?: number;
	disabled?: boolean;
}

const { min = 1, max = 999, disabled = false, name, modelValue } = defineProps<Props>();

watch(
	() => modelValue,
	(v: number) => {
		if (v < min) emit("update:modelValue", min);
		else if (v > max) emit("update:modelValue", max);
	},
	{ immediate: true }
);
</script>

<template>
	<div class="border rounded border-brown bg-white inline-flex">
		<button
			:disabled="disabled || modelValue <= min"
			class="group disabled:cursor-not-allowed pl-4 p-2"
			@click="emit('update:modelValue', modelValue - 1)"
		>
			<SvgIcon name="minus" class="group-disabled:text-gray" />
		</button>
		<div class="py-2">
			<input
				:name="name"
				:value="modelValue"
				type="number"
				:min="min"
				:max="max"
				class="text-base font-medium text-center outline-yellow disabled:cursor-not-allowed"
				:disabled="disabled"
				@input="
					(v) => {
						emit('update:modelValue', (v.target as HTMLInputElement).valueAsNumber);
					}
				"
			/>
		</div>
		<button
			:disabled="disabled || modelValue >= max"
			class="group disabled:cursor-not-allowed pr-4 p-2"
			@click="emit('update:modelValue', modelValue + 1)"
		>
			<SvgIcon name="plus" class="group-disabled:text-gray" />
		</button>
	</div>
</template>
